import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    InputAdornment,
    Autocomplete
} from '@mui/material';
import {
    ViewDayRounded,
    DirectionsCarFilledRounded 
} from '@mui/icons-material';
import {
    getCustomerById,
    getVehiclesModels
} from '../services/api';

const VehicleForm = ({ open, onClose, onSubmit, customerId }) => {
    const navigate = useNavigate();
    const [customer, setCustomer] = useState({});

    const [vehicleModels, setVehicleModels] = useState([]);
    const [selectedModel, setSelectedModel] = useState(null);

    const initialFormData = useMemo(() => ({
        customer_id: '',
        number: '',
        category_id: '',
        make_id: '',
        model_id: ''
    }), []);

    const [formData, setFormData] = useState(initialFormData);
    const fieldLabels = {
        number: 'Vehicle Number',
        model_id: 'Select model'
    };
    const [formErrors, setFormErrors] = useState({});

    const fetchCustomerData = async (id) => {
        try {
            const fetchCustomerDataById = await getCustomerById(id);
            if (fetchCustomerDataById.success) {
                setCustomer(fetchCustomerDataById.data);
                setFormData({ ...formData, customer_id: fetchCustomerDataById.data.id });
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    useEffect(() => {
        if (!open) {
            setFormData(initialFormData);
            setFormErrors({});
            resetValues();
        }
    }, [open, initialFormData]);

    const handleChange = (e, newValue) => {
        const { name, value } = e.target;
        const updatedValue = name === 'number' ? value.toUpperCase() : value; // Convert value to uppercase for 'number' field

        setFormData({ ...formData, [name]: updatedValue });
        if (formErrors[name]) {
            setFormErrors({ ...formErrors, [name]: null });
        }
        if (name !== 'number') {
            setSelectedModel(newValue);
            if (!newValue) {
                setFormData({ ...formData, category_id: null, make_id: null, model_id: null });
            } else {
                setFormData({ ...formData, category_id: newValue.category_id, make_id: newValue.make_id, model_id: newValue.id });
            }
        }
    };

    const handleSubmit = () => {
        const errors = validateForm(formData);
        if (Object.keys(errors).length === 0) {
            onSubmit(formData);
        } else {
            setFormErrors(errors);
        }
    };

    const validateForm = (data) => {
        const requiredFields = ['number', 'model_id'];
        const errors = {};
        if (!isValidVehicleNumber(data.number)) {
            errors.number = 'Invalid vehicle number format';
        }
        requiredFields.forEach(field => {
            if (!data[field]) {
                errors[field] = `${capitalizeFirstLetter(field.replace(/_/g, ' '))} is required`;
            }
        });
        return errors;
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    // Function to validate vehicle number
    const isValidVehicleNumber = (vehicleNumber) => {
        // Regular expression pattern
        const pattern = /[A-Z]{2}[0-9]{1,2}[A-Z]{1,2}[0-9]{1,4}/;
        return pattern.test(vehicleNumber);
    };

    const resetValues = () => {
        setVehicleModels([]);
    }

    const fetchVehicleModelData = async () => {
        try {
            const fetchVehicleModel = await getVehiclesModels();
            if (fetchVehicleModel.success) {
                setVehicleModels(fetchVehicleModel.data);
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const handleApiError = (error) => {
        if (error.message === 'Not authenticated') {
            navigate('/');
        } else {
            console.error(error);
        }
    };

    // To fetch category data when the dialog is opened
    useEffect(() => {
        if (open) {
            fetchVehicleModelData();
            fetchCustomerData(customerId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    // To reset form data and errors when the dialog is closed
    useEffect(() => {
        if (!open) {
            setFormData(initialFormData);
            setFormErrors({});
        }
    }, [open, initialFormData]);

    return (
        <Dialog open={open} onClose={onClose}>
            {/* Dialog contents */}
            <DialogTitle className="bg-secondary text-white mb-2">Vehicle Details ({customer.name || ''})</DialogTitle>
            <DialogContent>
                <form autoComplete="off">
                    <TextField
                        className="mb-2"
                        key="number"
                        name="number"
                        label={fieldLabels.number}
                        variant="standard"
                        color="primary"
                        fullWidth
                        value={formData.number}
                        onChange={handleChange}
                        error={!!formErrors.number}
                        helperText={formErrors.number}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <ViewDayRounded />
                                </InputAdornment>
                            ),
                        }}
                        required
                        autoComplete="off"
                        inputProps={{
                            style: { textTransform: 'uppercase' }
                        }}
                    />
                    <Autocomplete
                        label={fieldLabels.model_id}
                        options={vehicleModels}
                        getOptionLabel={(option) => option.name}
                        value={selectedModel || null}
                        onChange={handleChange}
                        isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                        }
                        renderInput={(params) => (
                            <TextField
                                required
                                error={!!formErrors.model_id}
                                {...params}
                                key={"model-" + params.id}
                                label={fieldLabels.model_id}
                                variant="standard"
                                placeholder="Search Model"                  
                                helperText={formErrors.model_id}
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <DirectionsCarFilledRounded />
                                        </InputAdornment>
                                    ),
                                }}
                                fullWidth
                            />
                        )}
                    />
                </form>
            </DialogContent>
            {/* Dialog actions */}
            <DialogActions>
                <Button color="error" onClick={onClose}>Cancel</Button>
                <Button variant="contained" onClick={handleSubmit}>Submit</Button>
            </DialogActions>
        </Dialog>
    );
};

export default VehicleForm;
