import React from 'react';

const PrintBookingDetails = ({ bookingDetails }) => {
  const printStyles = `
    small.pull-right.just-hide-print { display: none; }
    .print-bill { width: 90%; margin: 0 auto; }
    .page-footer { border-top: 1px solid #ccc; }
    .print-bill table, .print-bill tr { border: 1px solid #000; padding: 10px; width: 100%; margin: 0 auto; text-align: center; border-collapse: collapse; font-size: 14px; }
    .print-bill tr td, .print-bill tr th { border: 1px solid #000; text-align: center; }
    .print-bill h3 { text-align: center; }
    .print-bill .title { text-align: center; margin-top: 5px; margin-bottom: 5px; }
    .print-bill .border-none { border: none; }
    .print-bill .maindiv { display: inline-block; width: 100%; margin-bottom: 5px; font-size: 14px; }
    .print-bill.float-left { float: left; }
    .print-bill .float-right { float: right; }
    .print-bill table { padding: 5px; }
    .print-bill .align-center { text-align: center; margin-top: 5px; font-size:12px; }
  `;

  const printScript = `
    window.onload = function() {
      window.print();
    }
  `;

  const CurrencyRupee = new Intl.NumberFormat("en-IN");

  return (
    <html>
      <head>
        <title>Royal Car Wash-{bookingDetails.id}</title>
        <style>{printStyles}</style>
      </head>
      <body>
        <div className="print-bill">
          <h3 className="title">Royal Cash Wash</h3>
          <div className="maindiv">
            <div className="float-left">Bill Number: {bookingDetails.id}</div>
            <div className="float-left">Date : {new Date().toLocaleString()}</div>
            <div className="float-left">Customer : <strong>{bookingDetails.customer.name}</strong></div>
            <div className="float-left">Vehicle : <strong>{bookingDetails.vehicle.number}</strong></div>
          </div>
          <h4 className="title">Service(s)</h4>
          <table>
            <thead>
              <tr>
                <th>SI.No</th>
                <th>Name</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {bookingDetails.service_bills.map((service, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{service.service_id.name}</td>
                  <td><strong>&#8377; {CurrencyRupee.format(service.amount)}</strong></td>
                </tr>
              ))}
            </tbody>
          </table>
          <h4 className="title">Order Summary</h4>
          <div className="maindiv">
            {bookingDetails.services_amount && (
              <div>
                <span className="float-left">Service(s)</span>
                <span className="float-right"><strong>&#8377; {CurrencyRupee.format(bookingDetails.services_amount)}</strong></span>
              </div>
            )}
            {bookingDetails.accessory_amount > 0 && (
              <div>
                <span className="float-left">Accessory</span>
                <span className="float-right"><strong>&#8377; {CurrencyRupee.format(bookingDetails.accessory_amount)}</strong></span>
              </div>
            )}
            {bookingDetails.discount > 0 && (
              <div>
                <span className="float-left">Discount</span>
                <span className="float-right"><strong>(&#8377; {CurrencyRupee.format(bookingDetails.discount)})</strong></span>
              </div>
            )}
            <hr />
            <div>
              <span className="float-left">Overall Amount</span>
              <span className="float-right"><strong>&#8377; {CurrencyRupee.format(bookingDetails.total)}</strong></span>
            </div>
          </div>
          <h4 className="title">Payment(s)</h4>
          <div className="maindiv">
            {bookingDetails.payment_bills.map((payment, index) => (
              <div key={index} className="float-left">{payment.payment_type.name}: <strong>&#8377; {CurrencyRupee.format(payment.amount)}</strong></div>
            ))}
          </div>
          <div className="align-center">Thank you, Visit Again</div>
          <div className="align-center"><small>Application designed and developed by Web Behinds. (https://www.webbehinds.com/)</small></div>
        </div>
        <script>{printScript}</script>
      </body>
    </html>
  );
};

export default PrintBookingDetails;
